<template>
  <div class="">
    <div class="d-flex mb-3" style="justify-content: flex-end">
      <div
        @click="downloadPDF()"
        style="padding: 12px 18px; background: #36f; color: #fff; font-size: 14px; cursor: pointer">
        Завантажити PDF
      </div>
    </div>
    <div v-if="declaration" class="document">
      <div class="d-flex flex-wrap-wrap text-center">
        <h1 class="w-100">Гальмівний стенд № {{ declaration.stand && declaration.stand.testing_center_id }}</h1>
      </div>
      <div class="result">
        <div class="result-item">
          <span>Декларація №:</span>
          {{ declaration.number }}
        </div>
        <div class="result-item">
          <span>Замовник:</span>
          {{ declaration.owner && declaration.owner.name }}
        </div>
        <div class="result-item">
          <span>Номерний знак:</span>
          {{ declaration.vehicle && declaration.vehicle.car_license_plate }}
        </div>
        <div class="result-item">
          <span>Дата реєстрації:</span>
        </div>
        <div class="result-item">
          <span>VIN - код:</span>
          {{ declaration.vehicle && declaration.vehicle.vin }}
        </div>
        <div class="result-item">
          <span>Рік виготовлення:</span>
          {{ declaration.vehicle && declaration.vehicle.issue_year }}
        </div>
        <div class="result-item">
          <span>Тип ТЗ:</span>
          {{ declaration.vehicle && declaration.vehicle.vehicle_type && declaration.vehicle.vehicle_type.name }}
        </div>
        <div class="result-item">
          <span>Категорія:</span>
          {{ declaration.vehicle && declaration.vehicle.category && declaration.vehicle.category.name }}
        </div>
        <div class="result-item">
          <span>Марка:</span>
          {{ declaration.vehicle && declaration.vehicle.manufacturer && declaration.vehicle.manufacturer.name }}
        </div>
        <div class="result-item">
          <span>Модель:</span>
          {{ declaration.vehicle && declaration.vehicle.model && declaration.vehicle.model.name }}
        </div>
        <div class="result-item">
          <span>Колір:</span>
          {{ declaration.vehicle && declaration.vehicle.color && declaration.vehicle.color.name }}
        </div>
        <div class="result-item">
          <span>Об’єм двигуна:</span>
          {{ declaration.vehicle && declaration.vehicle.engine_volume }}
        </div>
        <div class="result-item">
          <span>Тип пального:</span>
          {{ declaration.vehicle && declaration.vehicle.fuel_type && declaration.vehicle.fuel_type.name }}
        </div>
        <div class="result-item">
          <span>Показник одометра:</span>
          {{ declaration.odometr_value }}
        </div>
        <div class="result-item">
          <span>Маса:</span>
          {{ declaration.vehicle && declaration.vehicle.mass }} кг
        </div>
        <div class="result-item">
          <span>Кількість вісей:</span>
          {{ declaration.vehicle && declaration.vehicle.axis_number }}
        </div>
      </div>
      <div class="title text-center mt-5">Результати вимірювання</div>
      <div class="result-table mb-3 mt-5">
        <div class="result-table__col">
          <div></div>
          <div>Гальмівна сила ліва(Pr1)</div>
          <div>Гальмівна сила права(Pr2)</div>
          <div>Коефіціент нерівномірності, %</div>
          <div>Питома гальмівна сила</div>
          <div>Тривалість спрацьовування, с. ліва</div>
          <div>Тривалість спрацьовування, с. права</div>
        </div>
        <div
          v-for="(e, index) in declaration.measurements"
          :key="index"
          class="result-table__col">
          <div>Вісь {{ index === 0 ? 'СГС' : index}}</div>
          <div>{{ e.left_brake_force.toFixed(2) }}</div>
          <div>{{ e.right_brake_force.toFixed(2) }}</div>
          <div>{{ e.stopping_coefficient.toFixed(2) }}</div>
          <div>{{ e.specific_brake_force.toFixed(2) }}</div>
          <div>{{ e.left_breaking_time.toFixed(2) }}</div>
          <div>{{ e.right_breaking_time.toFixed(2) }}</div>
        </div>
      </div>
      <div class="title text-center">Оцінка результатів розрахунку</div>
      <div class="result-table mb-3 mt-5">
        <div class="result-table__col">
          <div></div>
          <div>Макс. гальмівнасила, кН</div>
          <div>Загальна питомагальмівна сила, %</div>
          <div>Різниця,%</div>
          <div>Тривалість спрацьовування, с</div>
        </div>
        <div v-if="declaration.measurements.length && declaration.measurements[0]" class="result-table__col">
          <div>Робоча гальмівнасистема (РГС)</div>
          <div>{{ declaration.evaluation.main.max_brake_force.toFixed(4) }}</div>
          <div>{{ declaration.evaluation.main.specific_brake_force.toFixed(4) }}</div>
          <div>{{ declaration.evaluation.main.difference.toFixed(4) }}</div>
          <div>{{ declaration.evaluation.main.breaking_time.toFixed(4) }}</div>
        </div>
        <div v-if="declaration.measurements.length && declaration.measurements[1]" class="result-table__col">
          <div>Стоянкова гальмівнасистема (СГС)</div>
          <div>{{ declaration.evaluation.park.max_brake_force.toFixed(4) }}</div>
          <div>{{ declaration.evaluation.park.specific_brake_force.toFixed(4) }}</div>
          <div>{{ declaration.evaluation.park.difference.toFixed(4) }}</div>
          <div>{{ declaration.evaluation.park.breaking_time.toFixed(4) }}</div>
        </div>
        <div v-if="declaration.measurements.length && declaration.measurements[2]" class="result-table__col">
          <div>Статична вага, т</div>
          <div style="height: calc(100% - 46px)">{{ declaration.evaluation.total_weight.toFixed(4) }}</div>
        </div>
      </div>
      <div class="result-description">
        <b>Нормативні значення:</b>
        <b>Ефективність РГС КТЗ</b>
        <ul>
          <li>Зусилля на органі керування не більше 400 для М1, та 686 для інших КТЗ.</li>
          <li>Загальна питома гальмівна сила (Норматив: не менше ніж 0,45 для КТЗ категорії N2, N2G, N3, N3G; 0,5 для інших КТЗ)</li>
          <li>Коефіціент нерівномірності гальмівних сил коліс, % (Норматив: не більше 30%)</li>
          <li>Тривалість спрвцьовування вісі, с (Норматив: не більше 0,5 для КТЗ з гідроприводом; 0,8 для інших КТЗ)</li>
          <li>Значення коефіціентів сумісності ланок автопоїзда мають бути менше ніж 0,9</li>
          <li>Асинхронність тривалостей спрацьовування РГС ланок автопоїзда має бути не більше ніж 0,3с</li>
        </ul>
        <b>Ефективність СГС КТЗ</b>
        <ul>
          <li>Зусилля на органі керування не більше 392 для М1, та 588 для інших КТЗ.</li>
          <li>Загальна питома гальмівна сила (Норматив: не менше ніж 0,16 для КТЗ категорії N2, N2G, N3, N3G; 0,12 для інших КТЗ)</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'declarationDocument',
  data () {
    return {
      id: this.$router.currentRoute.params['number'],
    }
  },
  computed: {
    declaration () {
      let declaration = this.$store.getters.declarationById(this.id)
      if (!declaration.length) this.getDeclaration(this.id)
      return declaration[0]
    },
  },
  methods: {
    ...mapActions(['getDeclaration']),
    downloadPDF () {
      let params = new URLSearchParams()
      params.append('declaration_number', this.id)
      this.$api.getDoc(`stand/report/?${params}`)
        .then(response => {
          const link = document.createElement('a')
          document.body.appendChild(link)
          link.href = URL.createObjectURL(response)
          link.download = `Report_${this.id}`
          link.click()
          URL.revokeObjectURL(link.href)
          document.body.removeChild(link)
        })
    },
  },
}

</script>
